<template>
  <el-dialog :visible="dialogVisible"
             @open="open"
             :before-close="close">
    <div slot="title"
         class="left_top">
      <div class="left_icon">
        <span></span>
        <span>{{ title }}</span>
      </div>
    </div>
    <div class="dialog_top">
      <span>身份证图:</span>
      <div class="flex_between">
        <div class="card-box">
          <img v-if="detailInfo.idCardFront"
               :src="detailInfo.idCardFront"
               alt />
          <img v-else
               src="https://file.lgbfss.com/www/zan_xu/card_font_bg.png"
               alt />
          <p style="margin: 0; font-size: 14px">身份证头像面</p>
        </div>
        <div class="card-box">
          <img v-if="detailInfo.idCardBack"
               :src="detailInfo.idCardBack"
               alt />
          <img v-else
               src="https://file.lgbfss.com/www/zan_xu/card_back_bg.png"
               alt />
          <p style="margin: 0; font-size: 14px">身份证国徽面</p>
        </div>
      </div>
    </div>
    <el-descriptions title="用户信息">
      <el-descriptions-item label="员工姓名">{{ detailInfo.name }}</el-descriptions-item>
      <el-descriptions-item label="性别">{{ detailInfo.sex }}</el-descriptions-item>
      <el-descriptions-item label="年龄">
        <span v-if="detailInfo.idCardNumber">{{ year - parseInt(detailInfo.idCardNumber.slice(6, 10)) }}</span>
      </el-descriptions-item>
      <el-descriptions-item label="手机号">{{ detailInfo.phone }}</el-descriptions-item>
      <el-descriptions-item label="身份证号">{{ detailInfo.idCardNumber }}</el-descriptions-item>
      <el-descriptions-item label="地址">{{ detailInfo.address }}</el-descriptions-item>
      <el-descriptions-item label="注册时间">{{ detailInfo.create_time }}</el-descriptions-item>
      <el-descriptions-item label="紧急联系人">{{ detailInfo.sosUserName }}</el-descriptions-item>
      <el-descriptions-item label="联系方式">{{ detailInfo.sosUserPhone }}</el-descriptions-item>
      <el-descriptions-item label="关系">{{ detailInfo.sosRelation }}</el-descriptions-item>
      <el-descriptions-item label="联系地址">{{detailInfo.address }}</el-descriptions-item>
      <el-descriptions-item label="入职合同">
        <span v-if=" detail.enterContractStatus==='0'">未创建</span>
        <span v-if=" detail.enterContractStatus==='2'">签署完成</span>
        <span v-if=" detail.enterContractStatus==='100'">待发起</span>
        <span v-if=" detail.enterContractStatus==='101'">未签署</span>
        <span v-if=" detail.enterContractStatus==='103'">已完成</span>
        <span v-if=" detail.enterContractStatus==='104'">审核不通过</span>
        <span v-if=" detail.enterContractStatus==='105'">待企业签署</span>
        <span v-if=" detail.enterContractStatus==='3'">签署失败</span>
      </el-descriptions-item>
      <el-descriptions-item label="工作性质">
        <span v-if="detail.kind==='1'">全职</span>
        <span v-if="detail.kind==='2'">灵活用工</span>
        <span v-if="detail.kind==='0'">未分配</span>
      </el-descriptions-item>
      <el-descriptions-item label="用工企业"
                            v-if="detail.enterType">
        <span v-if="detail.workEnterprise">{{detail.workEnterprise.name}}</span>
      </el-descriptions-item>
      <el-descriptions-item label="企业区域"
                            v-if="detail.enterType">
        <span v-if="detail.area">{{detail.area.provinceName}}-{{detail.area.cityName}}</span>
      </el-descriptions-item>
      <el-descriptions-item label="企业门店"
                            v-if="detail.enterType">
        <span v-if="detail.store">{{detail.store.name}}</span>
      </el-descriptions-item>
      <el-descriptions-item label="入职时间"
                            v-if="detail.enterType">
        <span v-if="detail.enterTime">{{detail.enterTime}}</span>
      </el-descriptions-item>
    </el-descriptions>
    <el-form ref="ruleForm"
             :model="ruleForm"
             label-width="80px"
             :rules="rules">
      <el-row type="flex"
              class="row-bg"
              justify="space-between"
              v-if="!detail.enterType">
        <el-col :span="8">
          <el-form-item label="用工企业"
                        prop="workEnterpriseCode">
            <el-select remote
                       reserve-keyword
                       :remote-method="query"
                       @change="getCity"
                       v-model="ruleForm.workEnterpriseCode"
                       clearable
                       filterable
                       placeholder="请输入企业名称"
                       style="margin-right:10px;">
              <el-option v-for="item in eList"
                         :key="item.code"
                         :label="item.name"
                         :value="item.code"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="企业区域"
                        prop="workEnterpriseAreaCode">
            <el-select v-model="ruleForm.workEnterpriseAreaCode"
                       @change="queryArea"
                       style="margin-right:10px;"
                       filterable
                       clearable
                       :placeholder="'请输入企业区域'">
              <el-option-group v-for="group in areaSelectList"
                               :key="group.provinceName"
                               :label="group.provinceName">
                <span style="color:red"
                      slot="label">{{ group.provinceName }}</span>
                <el-option v-for="item in group.citys"
                           :key="item.code"
                           :label="item.cityName"
                           :value="item.code"></el-option>
              </el-option-group>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="企业门店"
                        prop="workEnterpriseStoreCode">
            <el-select remote
                       reserve-keyword
                       v-model="ruleForm.workEnterpriseStoreCode"
                       clearable
                       filterable
                       placeholder="请输入企业门店"
                       style="margin-right: 10px;">
              <el-option v-for="item in storeList"
                         :key="item.code"
                         :label="item.name"
                         :value="item.code"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex"
              class="row-bg"
              justify="start"
              v-if="!detail.enterTime||!detail.enterType||detail.kind==='0'">
        <el-col :span="8"
                v-if="!detail.enterTime">
          <el-form-item label="入职时间"
                        prop="enterTime">
            <el-date-picker v-model="ruleForm.enterTime"
                            type="datetime"
                            placeholder="选择入职时间"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="8"
                v-if="detail.kind==='0'">
          <el-form-item label="工作性质"
                        prop="kind">
            <el-radio-group v-model="ruleForm.kind">
              <el-radio label="1">全职</el-radio>
              <el-radio label="2">灵活用工</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="8"
                v-if="!detail.enterType">
          <el-form-item label="合同模板">
            <el-select remote
                       reserve-keyword
                       :remote-method="query"
                       v-model="contractTemplate"
                       @change="templateChange"
                       @clear="clearTemlate"
                       v-selectLoadMore="selectLoadMore"
                       clearable
                       filterable
                       placeholder="请输入合同模板"
                       style="margin-right:10px;">
              <el-option v-for="item in contractTemplateList"
                         :key="item.code"
                         :label="item.name"
                         :value="item.code"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-card v-if="templateShow"
               style="margin:10px 0">
        <div style="flex-grow: 1">
          <!--   :prop="'domains.' + i + '.value'"
            :rules="{
          required: true, message: `${item.label}不能为空`, trigger: 'blur' }"-->
          <Divider :title="'模板参数配置'"
                   style="margin:10px 0"></Divider>

          <el-form-item v-for="(item, i) in templeteObject.fields"
                        :key="i">
            <div slot="label">
              <div class="label_class">
                <span style="width: 100px;">
                  {{ item.label === '甲方名称'
                  ? '甲方签署人'
                  : item.label === '乙方名称'
                  ? '乙方签署人'
                  : item.label}}
                </span>
              </div>
            </div>
            <el-input v-model="item.value"
                      :placeholder="item.label"
                      v-if="item.type === 'text'"
                      @input="inputChange"></el-input>

            <div v-if="item.type === 'subject'">
              <SelectSubject v-model="item.value"></SelectSubject>
            </div>

            <el-input type="number"
                      v-model="item.value"
                      :placeholder="item.label"
                      v-if="item.type === 'number'"
                      @input="inputChange"></el-input>

            <el-input v-model="item.value"
                      :placeholder="item.label"
                      v-if="item.type === 'defined'"
                      @input="inputChange"></el-input>

            <el-date-picker style="width: 100%"
                            v-if="item.type === 'time'"
                            v-model="item.value"
                            type="date"
                            :placeholder="item.label"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            @input="inputChange"></el-date-picker>
          </el-form-item>
        </div>
      </el-card>

      <el-form-item label="审核结果"
                    prop="isVerifyPass"
                    v-if="!templateShow">
        <el-radio-group v-model="ruleForm.isVerifyPass"
                        @input="getGroup">
          <el-radio :label="true">通过</el-radio>
          <el-radio :label="false">拒绝</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="备注">
        <el-input type="textarea"
                  :disabled="templateShow"
                  v-model="ruleForm.noPassEnterReason"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary"
                   v-if="!templateShow"
                   @click="onSubmit('ruleForm')">提交</el-button>
        <el-button type="primary"
                   v-else
                   @click="onSubmitContract('ruleForm')">创建合同</el-button>
        <el-button @click="closeDialog('ruleForm')">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>
<script>
import { api } from '/src/api/base';
import { ContractTemplate } from '../contract/contractParse';
// 员工详情
const staffInfo = api()('staff.info.json');
// 入职审核
export const staffVerifyEnter = api()('staff.verify.enter.json');
// 企业列表
export const workEnterpriseList = api()('work_enterprise.list.json');
// 企业模板详情
export const contractTemplateEnterpriseInfo = api()('contractTemplate.enterprise.info.json');
// 获取企业区域列表
export const workEnterpriseAreaList = api()('work_enterprise_area.list.json');
// 获取企业区域门店
export const workEnterpriseStoreList = api()('work_enterprise_store.list.json');
// 企业名称获取企业区域列表
export const workEnterpriseAreaContainArea = api()('work_enterprise_area.containArea.json');
// 企业区域下模板列表
export const contractTemplateEnterpriseList = api('')(
  'contractTemplate.enterprise.list.json'
);
// 编辑入职员工信息
export const staffEdit = api()('staff.edit.json');
// 创建合同
export const contractAdd = api()('contract.add.json');
export default {
  components: {
    Divider: () => import('/src/components/divider.vue'),
    SelectSubject: () => import('/src/views/contract/selectSubject')
  },
  data () {
    return {
      detailInfo: {},
      detail: {},
      year: new Date().getFullYear(),
      eList: [],
      storeList: [],
      areaSelectList: [],
      choice: false,
      templeteObject: null,
      name: '',
      type: '',
      ruleForm: {
        enterTime: '',
        isVerifyPass: '',
        noPassEnterReason: '',
        workEnterpriseCode: '',
        workEnterpriseAreaCode: '',
        workEnterpriseStoreCode: '',
        kind: ''
      },
      contractTemplate: '',
      rules: {
        workEnterpriseCode: [
          {
            required: true,
            message: '请选择企业',
            trigger: 'change',
          },
        ],
        workEnterpriseAreaCode: [
          {
            required: true,
            message: '请选择区域',
            trigger: 'change',
          },
        ],
        workEnterpriseStoreCode: [
          {
            required: true,
            message: '请选择门店',
            trigger: 'change',
          },
        ],
        enterTime: [{

          required: true,
          message: '请选择日期',
          trigger: 'change',
        },
        ],
        isVerifyPass: [
          {
            required: true,
            message: '请选择审核结果',
            trigger: 'change',
          },
        ],
        kind: [
          {
            required: true,
            message: '请选择工作性质',
            trigger: 'change',
          },
        ]
      },
      options: [],
      templateShow: false,
      contractTemplateList: [

      ],
      totalPage: '',
      pageNumber: 1,
      pageSize: 10,
    };
  },
  props: {
    dialogVisible: Boolean,
    title: String,
    code: String
  },

  methods: {

    getTemplate () {
      contractTemplateEnterpriseList({
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        workEnterpriseCode: this.ruleForm.workEnterpriseCode,
        workEnterpriseArea: this.ruleForm.workEnterpriseAreaCode,
      }).then(res => {
        this.totalPage = Math.ceil(res.total / 10);
        this.total = res.total;
        this.contractTemplateList = this.contractTemplateList.concat(res.list)
      });
    },
    async selectLoadMore () {

      this.pageNumber = this.pageNumber + 1;
      if (this.pageNumber > this.totalPage) return;
      await this.getTemplate(); // 请求接口
    },
    getGroup (val) {

      if (!val) {
        this.$refs.ruleForm.rules.enterTime[0].required = false
        this.$refs.ruleForm.rules.kind[0].required = false
      }
    },
    TimeChange (e) {
      var that = this
      var date = new Date(e);
      var y = date.getFullYear(); // 年
      var m = date.getMonth() + 1; // 月
      m = m < 10 ? ('0' + m) : m;
      var d = date.getDate(); // 日
      d = d < 10 ? ('0' + d) : d;
      var h = date.getHours(); // 时
      h = h < 10 ? ('0' + h) : h;
      var min = date.getMinutes(); // 分
      min = min < 10 ? ('0' + min) : min;
      var s = date.getSeconds(); // 秒
      s = s < 10 ? ('0' + s) : s;
      that.ruleForm.enterTime = y + '-' + m + '-' + d + '-' + h + ':' + min + ':' + s;//拼在一起

    },
    inputChange () {

    },
    goCity (val) {
      const params = {
        workEnterpriseCode: val
      };
      workEnterpriseAreaContainArea(params).then(res => {
        this.areaSelectList = res
      });
    },
    goArea (val) {
      workEnterpriseStoreList({
        workEnterpriseAreaCode: val, pageNumber: 1,
        pageSize: 1000
      }).then(res => {
        this.storeList = res.list
        this.getTemplate()
        console.log(this.storeList);
      });
    },
    getCity (val) {
      if (val) {
        const params = {
          workEnterpriseCode: val
        };
        workEnterpriseAreaContainArea(params).then(res => {
          this.areaSelectList = res
          this.ruleForm.workEnterpriseAreaCode = ''
          this.ruleForm.workEnterpriseStoreCode = ''
          this.contractTemplateList = []
          this.templateShow = ''
          this.contractTemplate = ''
        });
      }
      else {
        this.ruleForm.workEnterpriseCode = ''
        this.ruleForm.workEnterpriseAreaCode = ''
        this.areaSelectList = []
        this.ruleForm.workEnterpriseStoreCode = ''
        this.storeList = []
        this.contractTemplateList = []
        this.templateShow = ''
        this.contractTemplate = ''
      }

    },
    choiceChange (val) {
      val.subjectAccount = ''
      val.subjectName = ''
    },
    async templateChange (val) {

      const result = await contractTemplateEnterpriseInfo({ code: val });
      this.templeteObject = new ContractTemplate(result);
      console.log(this.templeteObject.fields);
      this.templateShow = true
      this.name = result.name
      this.type = result.type
    },
    queryArea (val) {
      if (val) {
        workEnterpriseStoreList({
          workEnterpriseAreaCode: val,
          pageNumber: 1,
          pageSize: 1000
        }).then(res => {
          this.storeList = res.list
          this.ruleForm.workEnterpriseStoreCode = ''
          this.contractTemplateList = []
          this.templateShow = ''
          this.contractTemplate = ''
          this.getTemplate()
          console.log(this.storeList);
        });
      }
      else {
        this.ruleForm.workEnterpriseAreaCode = ''
        this.contractTemplateList = []
        this.templateShow = ''
        this.contractTemplate = ''
        this.ruleForm.workEnterpriseStoreCode = ''
      }

    },
    enterpriseList (val) {

      const params = {
        keywords: val,
        pageNumber: 1,
        pageSize: 1000
      };
      workEnterpriseList(params).then(res => {
        this.eList = res.list

      });
    },
    createContract () {
      const a = this.templeteObject.getReplaceConfigTwo()

      const isShow = new Set(a.map(m => m.psnId)).size === this.templeteObject.fields.filter(it => it.type === 'subject').length
      console.log(isShow);
      if (isShow) {
        contractAdd({
          name: this.name,
          workEnterpriseCode: this.ruleForm.workEnterpriseCode,
          workEnterpriseAreaCode: this.ruleForm.workEnterpriseAreaCode,
          workEnterpriseStoreCode: this.ruleForm.workEnterpriseStoreCode,
          templateCode: this.contractTemplate,
          content: JSON.stringify(this.templeteObject.getReplaceContract()),
          config: '[' + JSON.stringify(this.templeteObject.getReplaceConfigTwo()) + ']',
          type: this.type

        }).then(() => {

          this.$message({
            message: '合同创建成功',
            type: 'success'
          })
        });
      }
      else {
        this.$message.info('甲方签署人和乙方签署人不能为同一人');
      }

    },
    onSubmitContract (formName) {
      var domains = this.templeteObject.fields.every(it => {
        if (it.type === 'subject') {
          if (it.value.psnId != '') {
            return true
          }
          else {
            return false
          }
        }
        else {
          if (it.value != '') {
            return true
          }
          else {
            return false
          }
        }
      })
      if (!domains) {
        this.$message({
          message: '请输入合同模板信息',
          type: 'danger'
        })
      }
      else {
        this.$refs[formName].validate((valid) => {
          if (valid) {

            if (this.ruleForm.enterTime) {
              this.TimeChange(this.ruleForm.enterTime)
            }
            else {
              this.ruleForm.enterTime = this.detail.enterTime ? this.detail.enterTime : this.ruleForm.enterTime
            }
            console.log(this.ruleForm.enterTime);
            staffEdit({
              staffCode: this.code,
              enterTime: this.ruleForm.enterTime,
              workEnterpriseCode: this.ruleForm.workEnterpriseCode,
              workEnterpriseAreaCode: this.ruleForm.workEnterpriseAreaCode,
              workEnterpriseStoreCode: this.ruleForm.workEnterpriseStoreCode,
              kind: this.ruleForm.kind
            }).then(res => {
              console.log(res);
              this.createContract()
              setTimeout(() => {
                this.closeDialog(formName)
              }, 1000);

            });
          }
          else {
            return false
          }
        })
      }

    },

    query (val) {

      this.pageNumber = 1;
      this.enterpriseList(val);
    },
    onSubmit (formName) {
      console.log(this.ruleForm, this.$refs.ruleForm);
      this.$refs[formName].validate((valid) => {
        console.log(valid);
        if (valid) {
          var params = {}
          if (this.ruleForm.isVerifyPass) {
            this.TimeChange(this.ruleForm.enterTime)

            this.ruleForm.enterTime = this.detail.enterTime ? this.detail.enterTime : this.ruleForm.enterTime
            console.log(this.ruleForm.enterTime);
            params = {
              staffCode: this.code,
              ...this.ruleForm
            };
          }
          else {
            params = {
              staffCode: this.code,
              isVerifyPass: this.ruleForm.isVerifyPass,
              noPassEnterReason: this.ruleForm.noPassEnterReason,
              workEnterpriseCode: this.ruleForm.workEnterpriseCode,
              workEnterpriseAreaCode: this.ruleForm.workEnterpriseAreaCode,
              workEnterpriseStoreCode: this.ruleForm.workEnterpriseStoreCode,
              kind: this.ruleForm.kind,
            };
          }
          console.log(params);

          staffVerifyEnter(params).then(() => {


            this.closeDialog(formName)
          });
        } else {
          return false
        }
      })
    },
    resetForm (formName) {
      this.$refs[formName].resetFields()
    },
    close () {
      this.closeDialog('ruleForm')
    },
    open () {
      this.enterpriseList()
      this.geiInfo()
    },
    clearTemlate () {
      this.templateShow = false
    },
    async geiInfo () {

      await staffInfo({ staffCode: this.code }).then((res) => {
        this.detailInfo = res.user;
        this.detail = res

        // this.detail = res.contract.map(it => {
        //   return {
        //     ...it,
        //     enterType: it.type === '1' ? true : false
        //   }
        // })
        if (res.enterContractStatus === '2' || res.enterContractStatus === '100' || res.enterContractStatus === '101' || res.enterContractStatus === '103' || res.enterContractStatus === '105') {
          this.detail.enterType = true

        }
        else {
          this.detail.enterType = false
        }
        this.ruleForm.workEnterpriseCode = res.workEnterprise.code
        this.goCity(this.ruleForm.workEnterpriseCode)
        this.ruleForm.workEnterpriseAreaCode = res.area.code

        this.goArea(this.ruleForm.workEnterpriseAreaCode)
        this.ruleForm.workEnterpriseStoreCode = res.store.code

        this.ruleForm.kind = res.kind === '0' ? '' : res.kind


      });
    },
    closeDialog (val) {
      this.contractTemplate = ''
      this.templateShow = false
      this.resetForm(val)
      this.$emit('update:dialogVisible', false);
      this.$emit('func', this.dialogVisible);
    }
  }
};
</script>
<style>
.el-form-item__label {
  display: flex !important;
  padding-bottom: 0 !important;
}
</style>
<style lang="scss" scoped>
.header {
  padding-bottom: 15px;
  border-bottom: 1px solid #ebeef5;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ruleForm {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.el-form-item {
  text-align: left;
  margin: 0;
  margin-bottom: 20px;
  width: 100%;
}

.flex_between {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .card-box {
    margin: 10px;
    padding: 1px;
    text-align: center;
    color: grey;
    width: 50%;

    img {
      width: 100%;
      height: 100px;
    }
  }
}

.dialog_top {
  display: flex;
  align-items: center;
}
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
</style>
